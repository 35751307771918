.header-container-wrapper{
    width: 100%;
    height: 100%;
    background-color: white;
}
.header-container{
    border-top: 1px solid black;
    height: 85%;width: 100%;
    background-color: white;
    position: relative;
    font-family: Helvetica, Helvetica, Arial, sans-serif;
}
.name{
    position: absolute;
    top: 5%;
    left: 0;
    color: blue;
    font-family :Helvetica, Helvetica, Arial, sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
}
.initiative{
    position: absolute;
    bottom: 5%;
    left: 0;
    line-height: 23px;
    font-size: 16px;
    font-weight: 400;
    color: #8c8c8c;
}
.about{
    position: absolute;    
    top: 5%;
    right: 0;
    line-height: 23px;
    font-size: 16px;
    font-weight: 400;
    color: #8c8c8c;
}