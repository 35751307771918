@import url(https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,700;1,400&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
    border: 0;
    padding: 0;
    margin: 0;
    text-decoration: none;
    -webkit-text-decoration-style: none;
            text-decoration-style: none;
}

body {
    overflow-x: hidden;
    font-family: Noto Sans, sans-serif;
}

a {
    color: #0044ff;
    text-decoration: underline;
}

html {
    font-size: 62.5%;
}

.w100h100 {
    height: 100%;
    width: 100%;
}

.flex {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex-end {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.flex-start {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.flex-col {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
}

.flex-col-end {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-flow: column;
}

.flex-col-start {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-flow: column;
}

.pointer {
    cursor: pointer;
}


/* FONT SIZE AND WEIGHT*/

.f1 {
    font-size: 1rem;
}

.f1-2 {
    font-size: 1.2rem;
}

.f1-3 {
    font-size: 1.3rem;
}

.f1-5 {
    font-size: 1.5rem;
}

.f2 {
    font-size: 2rem;
}

.f3 {
    font-size: 3rem;
}

.f4 {
    font-size: 4rem;
}

.f5 {
    font-size: 5rem;
}

.f6 {
    font-size: 6rem;
}

.fb {
    font-weight: bolder;
}

.fbb {
    font-weight: 900;
}

.fl {
    font-weight: lighter;
}

.fll {
    font-weight: 100;
}


/* BACKGROUND AND FOREGROUND COLORS */

.red {
    color: red;
}

.black {
    color: black;
}

.white {
    color: white;
}

.blue {
    color: blue;
}

.mute-txt {
    color: lightgray;
}

.bg-dark {
    background-color: black;
}

.bg-white {
    background-color: white;
}

.bg-smoke {
    background-color: whitesmoke;
}

.bg-gray {
    background-color: lightgray;
}

.bg-blue {
    background-color: blue;
}

.bg-silver {
    background-color: silver;
}


/* Responsiveness */

@media screen and (max-width: 420px) {
    html {
        font-size: 22.5%;
    }
}

@media screen and (max-width: 450px) {
    html {
        font-size: 32.5%;
    }
}

@media screen and (max-width: 1000px) {
    html {
        font-size: 42.5%;
    }
}

@media screen and (max-width: 1200px) {
    html {
        font-size: 52.5%;
    }
}

@media screen and (max-width: 1400px) {
    html {
        font-size: 62.5%;
    }
}

@media screen and (min-width: 1500px) {
    html {
        font-size: 70%;
    }
}

@media screen and (min-width: 1600px) {
    html {
        font-size: 75%;
    }
}

@media screen and (min-width: 1700px) {
    html {
        font-size: 80%;
    }
}

@media screen and (min-width: 1800px) {
    html {
        font-size: 90%;
    }
}

@media screen and (min-width: 1900px) {
    html {
        font-size: 95%;
    }
}
.home-wrapper-container {
    display: grid;
    grid-template-columns: minmax(0rem, 1fr) minmax(-webkit-min-content, 1920px) minmax(0rem, 1fr);
    grid-template-columns: minmax(0rem, 1fr) minmax(min-content, 1920px) minmax(0rem, 1fr);
    grid-template-rows: minmax(100vh, -webkit-min-content);
    grid-template-rows: minmax(100vh, min-content);
    /* box-sizing: border-box; */
    /* position: relative; */
    padding-left: 2rem;
    padding-right: 2rem;
    grid-template-areas: ". home-container .";
}

.home-container {
    grid-area: home-container;
    /* background-color: lightgray; */
    display: grid;
    grid-template-columns: 350px minmax(-webkit-min-content, 1570px);
    grid-template-columns: 350px minmax(min-content, 1570px);
    /* grid-template-columns: 30% 70fr; */
    grid-template-rows: 150px minmax(85vh, -webkit-min-content);
    grid-template-rows: 150px minmax(85vh, min-content);
    grid-template-areas: "header header" "left-nav projectsContent";
}

.header {
    grid-area: header;
    width: 100%;
    height: 100%;
    border-bottom: 1px solid black;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    box-sizing: border-box;
}

.left-nav {
    grid-area: left-nav;
    height: 100fr;
    width: 350px;
    /* width: 30%; */
    border-right: 1px solid black;
    position: fixed;
    top: 150px;
    bottom: 0;
    left: 2rem;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 95fr 5fr;
    z-index: 3;
    overflow-x: hidden;
}

.left-nav-items {
    overflow-y: auto;
    /* color: lightgrey; */
    z-index: 1;
    overflow-x: hidden;
}

.left-nav-items:hover {
    color: black;
}

.left-nav-footer {
    display: flex;
    border-top: 1px solid black;
    /* align-items: flex-start; */
    box-sizing: border-box;
    padding-top: 1rem;
    z-index: 3;
    font-weight: 400;
    line-height: 23px;
    background-color: aqua;
    font-family: Helvetica, Helvetica, Arial, sans-serif;
    font-size: 16px;
    /* position: relative; */
}
.left-nav-footer a{
    text-decoration: none;
}

.projectsContent {
    grid-area: projectsContent;
    /* background-color: brown; */
}

.mobileNav {
    display: none;
}

.mobile-nav-footer a {
    text-decoration: none;
}


/* Responsivenss */

@media screen and (max-width: 850px) {
    /* .home-container {
        grid-area: home-container;
        position: relative;
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: 20vh minmax(80vh, min-content);
        grid-template-areas: "header"
                            "mobileView";
    } */
    .home-container {
        grid-area: home-container;
        /* background-color: lightgray; */
        display: grid;
        /* grid-template-columns: 100vw; */
        grid-template-columns: 100%;
        grid-template-rows: 20vh minmax(80vh, -webkit-min-content);
        grid-template-rows: 20vh minmax(80vh, min-content);
        grid-template-areas: "header" "mobileView";
    }
    .left-nav {
        grid-area: left-nav;
        display: none;
    }
    .projectsContent {
        display: none;
    }
    .mobileNav {
        /* position: absolute; */
        grid-area: mobileView;
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: 95fr 5fr;
    }
    .mobile-nav-items {
        /* background-color: cadetblue; */
    }
    .mobile-nav-footer {
        display: flex;
        border-top: 1px solid black;
        box-sizing: border-box;
        padding-top: 1rem;
        padding-bottom: 1rem;
        z-index: 3;
        font-weight: 400;
        line-height: 23px;
        background-color: white;
        font-family: Helvetica, Helvetica, Arial, sans-serif;
        font-size: 16px;
        position: fixed;
        bottom: 0;
        right: 2rem;
        left: 2rem;
        box-sizing: border-box;
    }
}
.header-container-wrapper{
    width: 100%;
    height: 100%;
    background-color: white;
}
.header-container{
    border-top: 1px solid black;
    height: 85%;width: 100%;
    background-color: white;
    position: relative;
    font-family: Helvetica, Helvetica, Arial, sans-serif;
}
.name{
    position: absolute;
    top: 5%;
    left: 0;
    color: blue;
    font-family :Helvetica, Helvetica, Arial, sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
}
.initiative{
    position: absolute;
    bottom: 5%;
    left: 0;
    line-height: 23px;
    font-size: 16px;
    font-weight: 400;
    color: #8c8c8c;
}
.about{
    position: absolute;    
    top: 5%;
    right: 0;
    line-height: 23px;
    font-size: 16px;
    font-weight: 400;
    color: #8c8c8c;
}
.nav-item-container-wrapper{
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: minmax(40px,-webkit-min-content);
    grid-template-rows: minmax(40px,min-content);
    border-bottom: 1px solid black;
    position: relative;
    box-sizing: border-box;
    font-family :Helvetica, Helvetica, Arial, sans-serif;
    font-feature-settings: "liga", "lnum";
    background-color: white;
}
.nav-item-container-wrapper:hover{
    /* color: lightgrey; */
}
.item-heading{
    box-sizing: border-box;
    padding-top: 0.5rem;
    display: flex;
    font-family :Helvetica, Helvetica, Arial, sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    /* color: #8c8c8c; */
}
.item-date{
    font-family :Helvetica, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    box-sizing: border-box;
    padding-right: 1rem;
}
.item-para{
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;

    /* position: absolute;
    right: 1rem;
    font-family :Helvetica, Helvetica, Arial, sans-serif;
    font-weight: 200;
    top: 16%; */
}
.nav-item-container-wrapper p{
    /* font-size: 100px; */
    /* font: inherit; */
    /* vertical-align: baseline; */
    /* font-weight: 300; */
 
}

.nav-item-container-wrapper h2{

}
.active{
    color: red;
}
.project-container-wrapper {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: minmax(60vh, -webkit-min-content);
    grid-template-rows: minmax(60vh, min-content);
    border-bottom: 1px solid black;
}

.project-container {
    display: grid;
    padding-left: 3%;
    padding-bottom: 3%;
    grid-template-columns: 70% 30%;
    font-family: Helvetica, Helvetica, Arial, sans-serif;
    grid-template-rows: 10vh minmax(60vh, -webkit-min-content);
    grid-template-rows: 10vh minmax(60vh, min-content);
    grid-template-areas: "project-container-headings project-container-headings" "project-container-content-left .";
}

.project-container-headings {
    grid-area: project-container-headings;
    position: -webkit-sticky;
    position: sticky;
    top: 112px;
    z-index: 3;
    display: flex;
    align-items: flex-start;
    box-sizing: border-box;
    padding-top: 8px;
}

.project-heading {
    flex: 2 1;
    font-family: Helvetica, Helvetica, Arial, sans-serif;
    line-height: 23px;
    font-size: 16px;
    font-weight: 700;
}

.project-date {
    flex: 1 1;
    line-height: 23px;
    font-size: 16px;
    font-weight: 400;
    font-family: Helvetica, Helvetica, Arial, sans-serif;
    color: #8c8c8c;
}

.project-container-content-left {
    grid-area: project-container-content-left;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: minmax(20vh, -webkit-min-content) minmax(40vh, -webkit-min-content);
    grid-template-rows: minmax(20vh, min-content) minmax(40vh, min-content);
}

.project-container-content-left-img {
    /* background-color: white; */
    /* display: flex; */
    /* overflow: hidden; */
}

.project-container-content-left-content {
    box-sizing: border-box;
    padding-top: 2rem;
    text-align: left;
    position: relative;
    font-family: Helvetica, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
}

.project-container-content-right {
    grid-area: project-container-content-right;
    text-align: end;
    font-family: Helvetica, Helvetica, Arial, sans-serif;
    /* font-weight: 200; */
    /* line-height: 15px; */
}

.project-container-content-left-content p {
    /* line-height: 23px; */
}

.is-pinned {
    color: red;
}

.awssld {
    width: 550px;
    height: 440px;
    --slider-height-percentage: 60%;
    --slider-transition-duration: 200ms;
    --organic-arrow-thickness: 20px;
    --organic-arrow-border-radius: 30px;
    --organic-arrow-height: 40px;
    --organic-arrow-color: #26456f;
    --control-button-width: 40%;
    --control-button-height: 80%;
    --control-button-background: transparent;
    --control-bullet-color: #fafafa;
    --control-bullet-active-color: #d5d5d5;
    --loader-bar-color: transparent;
    --loader-bar-height: 1px;
}


/* Responsiveness */

@media screen and (max-width: 1920px) {
    .project-container-content-div {
        width: 550px;
    }
}
.mobile-nav-item-container-wrapper{
    /* color: #8c8c8c; */
    display: grid;
    grid-template-columns: 100%;
    /* grid-template-columns: minmax(min-content,300px); */
    grid-template-rows: minmax(40px,-webkit-min-content);
    grid-template-rows: minmax(40px,min-content);
    border-bottom: 1px solid black;
    position: relative;
    box-sizing: border-box;
    font-family :Helvetica, Helvetica, Arial, sans-serif;
    font-feature-settings: "liga", "lnum";
}
.mobile-nav-item-container-wrapper:hover{
    color: lightgrey;
}
.mobile-item-heading{
    display: flex;
    font-family :Helvetica, Helvetica, Arial, sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    /* color: #8c8c8c; */
    /* color: black; */
}
.mobile-item-date{
    font-family :Helvetica, Helvetica, Arial, sans-serif;
    font-weight: 400;
    color: #8c8c8c;
}
.mobile-item-para{
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;

    /* position: absolute;
    right: 1rem;
    font-family :Helvetica, Helvetica, Arial, sans-serif;
    font-weight: 200;
    top: 16%; */
}

.mobile-nav-item-container-wrapper p{
    /* font-size: 100px; */
    /* font: inherit; */
    /* vertical-align: baseline; */
    /* font-weight: 300; */
 
}

.mobile-nav-item-container-wrapper h2{

}
.about-container-wrapper{
    display: grid;
    grid-template-columns: minmax(0rem, 1fr) minmax(-webkit-min-content, 1920px) minmax(0rem, 1fr);
    grid-template-columns: minmax(0rem, 1fr) minmax(min-content, 1920px) minmax(0rem, 1fr);
    grid-template-rows: 100vh;
    /* grid-template-rows: minmax(100vh,min-content); */
    padding-left: 2rem;
    padding-right: 2rem;
    grid-template-areas: ". about-container .";
}
.about-container{
    grid-area: about-container;
    display: grid;
    grid-template-columns: 350px minmax(-webkit-min-content,1570px);
    grid-template-columns: 350px minmax(min-content,1570px);
    /* grid-template-columns: 25% 75%; */
    grid-template-rows: 150px minmax(80vh, -webkit-min-content);
    grid-template-rows: 150px minmax(80vh, min-content);
    /* grid-template-rows: 150px 90vh; */
    grid-template-areas: "about-header about-header"
                         "about-left-nav about-content";
}
.about-header{
    grid-area: about-header;
    width: 100%;
    height: 100%;
    border-bottom: 1px solid black;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    box-sizing: border-box;
}
.about-left-nav{
    grid-area: about-left-nav;
    height: 100fr;
    width: 350px;
    border-right: 1px solid black;
    /* display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 95% 5%; */
    position: fixed;
    top: 150px;
    bottom: 0;
    left: 2rem;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 95fr 5fr;
}
.about-left-nav-items {
    overflow-y: auto;
    overflow-x: hidden;
}
.about-content{
    grid-area: about-content;
    background-color: white;
    display: grid;
    grid-template-areas: "aboutContentDiv";
}
.aboutContentDiv{grid-area: aboutContentDiv; 
    background-color: white; 
    width: 550px;
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    /* font-family: Helvetica; */
    font-family: Helvetica, Helvetica, Arial, sans-serif;
    padding-left: 20px;
    padding-top: 15px;
}

.about-left-nav-footer{
    border-top: 1px solid black;
    font-family: Helvetica, Helvetica, Arial, sans-serif;
    font-size: 16px;
position: relative;
}

.contactz{
    position: absolute;
    top: 20%;
}
.IGz{
position: absolute;
top: 20%;

}
.aboutMobileNav{
    display:none;
}
/* Responsiveness */

@media screen and (max-width: 850px) {
    .about-left-nav{
        display: none;
    } 
    .about-content{
        /* display: none; */
    }
    .about-container{
        grid-area: about-container;
        position: relative;
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: 150px 100fr;
        grid-template-areas: "about-header"
        "about-content"
                             /* "aboutMobileNav"; */
    }
    /* Removing padding left, and setting width 100% */ 
    .aboutContentDiv{
        padding-left: 0px;
        width: 100%;
    }

    /* .aboutMobileNav{
        grid-area: aboutMobileNav;
        height: 100%;
        width: 100%;
        position: absolute;
    }
    .aboutMobileNav{
        display:none;
    }    */
    
}
.about-nav-item-container-wrapper{
    /* color: #8c8c8c; */
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: minmax(40px,-webkit-min-content);
    grid-template-rows: minmax(40px,min-content);
    border-bottom: 1px solid black;
    position: relative;
    box-sizing: border-box;
    /* padding-right: 1rem; */
    font-family :Helvetica, Helvetica, Arial, sans-serif;
    font-feature-settings: "liga", "lnum";
}
.about-nav-item-container-wrapper:hover{
    /* color: lightgrey; */
}
.about-item-heading{
    /* display: flex;
    font-family :Helvetica, Helvetica, Arial, sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 23px; */
    /* color: #8c8c8c; */
    /* color: black; */
    box-sizing: border-box;
    padding-top: 0.5rem;
    display: flex;
    font-family :Helvetica, Helvetica, Arial, sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    /* color: #8c8c8c; */
}
.about-item-date{
    position: absolute;
    right: 1rem;top: 10px;
    font-family :Helvetica, Helvetica, Arial, sans-serif;
    font-weight: 200;
}
.about-item-para{
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;

    /* position: absolute;
    right: 1rem;
    font-family :Helvetica, Helvetica, Arial, sans-serif;
    font-weight: 200;
    top: 16%; */
}

.about-nav-item-container-wrapper p{
    /* font-size: 100px; */
    /* font: inherit; */
    /* vertical-align: baseline; */
    /* font-weight: 300; */
 
}

.about-nav-item-container-wrapper h2{

}
.link2{
    padding-left: 25px;
    padding-right: 25px;
}
.links{
    padding-top: 15px;
}
.projectpage-header-container-wrapper{
    width: 100%;
    height: 100%;
    background-color: white;
    border-bottom: 1px solid black;
}
.projectpage-header-container{
    border-top: 1px solid black;
    height: 85%;width: 100%;
    background-color: white;
    position: relative;
    font-family: Helvetica, Helvetica, Arial, sans-serif;
}
.projectpage-name{
    position: absolute;
    top: 5%;
    left: 0;
    color: #8c8c8c;
    font-family :Helvetica, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
}
.projectpage-initiative{
    position: absolute;
    bottom: 5%;
    left: 0;
    line-height: 23px;
    font-size: 16px;
    font-weight: 400;
    color: #8c8c8c;
}
.projectpage-about{
    position: absolute;    
    top: 5%;
    right: 0;
    line-height: 23px;
    font-size: 16px;
    font-weight: 400;
    color: #8c8c8c;
}
.singleproject-page-wrappper{
    display: grid;
    grid-template-columns: minmax(0rem, 1fr) minmax(-webkit-min-content, 1860px) minmax(0rem, 1fr);
    grid-template-columns: minmax(0rem, 1fr) minmax(min-content, 1860px) minmax(0rem, 1fr);
    padding-right: 2rem;
    padding-left: 2rem;
    background-color: white;
    box-sizing: border-box;
    grid-template-rows: 20vh minmax(80vh,-webkit-min-content);
    grid-template-rows: 20vh minmax(80vh,min-content);
    grid-template-areas: ". header ."
                    ". projectContent .";
    
}
.singleproject-header{
    grid-area: header;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    box-sizing: border-box;
    z-index: 3;
    background-color: white;
}
.singleproject-Content{
    grid-area: projectContent;
}
.mobile-project-container-wrapper {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: minmax(80vh, -webkit-min-content);
    grid-template-rows: minmax(80vh, min-content);
    border-bottom: 1px solid black;
}

.mobile-project-container {
    display: grid;
    /* padding-left: 4%; */
    padding-bottom: 3%;
    grid-template-columns: 60% 40%;
    font-family: Helvetica, Helvetica, Arial, sans-serif;
    grid-template-rows: 16vh minmax(64vh, -webkit-min-content);
    grid-template-rows: 16vh minmax(64vh, min-content);
    grid-template-areas: "mobile-project-container-headings mobile-project-container-headings" "mobile-project-container-content-left mobile-project-container-content-left";
}

.mobile-project-container-headings {
    grid-area: mobile-project-container-headings;
    position: -webkit-sticky;
    position: sticky;
    top: 15vh;
    z-index: 3;
    display: flex;
    align-items: flex-start;
    box-sizing: border-box;
    padding-top: 8px;
}

.mobile-project-heading {
    flex: 2 1;
    font-family: Helvetica, Helvetica, Arial, sans-serif;
    line-height: 23px;
    font-size: 16px;
    font-weight: 700;
}

.mobile-project-date {
    flex: 1 1;
    line-height: 23px;
    font-size: 16px;
    font-weight: 400;
    font-family: Helvetica, Helvetica, Arial, sans-serif;
    color: #8c8c8c;
}

.mobile-project-container-content-left {
    grid-area: mobile-project-container-content-left;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: minmax(15vh, -webkit-min-content) minmax(80vh, -webkit-min-content);
    grid-template-rows: minmax(15vh, min-content) minmax(80vh, min-content);
}

.mobile-project-container-content-left-img {
    /* background-color: white; */
    /* display: flex; */
    /* overflow: hidden; */
}

.mobile-project-container-content-left-content {
    box-sizing: border-box;
    padding-top: 2rem;
    text-align: left;
    position: relative;
    font-family: Helvetica, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
}

.mobile-project-container-content-right {
    grid-area: mobile-project-container-content-right;
    text-align: end;
    font-family: Helvetica, Helvetica, Arial, sans-serif;
    /* font-weight: 200; */
    /* line-height: 15px; */
}

.mobile-project-container-content-left-content p {
    /* line-height: 23px; */
}

.is-pinned {
    color: red;
}

.mobile-project-container-wrapper .awssld {
    width: 100%;
    height: auto;
    max-width: 550px;
    --slider-height-percentage: 80%;
    --slider-transition-duration: 200ms;
    --organic-arrow-thickness: 20px;
    --organic-arrow-border-radius: 30px;
    --organic-arrow-height: 40px;
    --organic-arrow-color: #26456f;
    --control-button-width: 40%;
    --control-button-height: 80%;
    --control-button-background: transparent;
    --control-bullet-color: #fafafa;
    --control-bullet-active-color: #d5d5d5;
    --loader-bar-color: transparent;
    --loader-bar-height: 1px;
}


/* Responsiveness */

@media screen and (max-width: 1900px) {
    .mobile-project-container-content-div {
        width: 300px;
    }
}
