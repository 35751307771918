.mobile-project-container-wrapper {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: minmax(80vh, min-content);
    border-bottom: 1px solid black;
}

.mobile-project-container {
    display: grid;
    /* padding-left: 4%; */
    padding-bottom: 3%;
    grid-template-columns: 60% 40%;
    font-family: Helvetica, Helvetica, Arial, sans-serif;
    grid-template-rows: 16vh minmax(64vh, min-content);
    grid-template-areas: "mobile-project-container-headings mobile-project-container-headings" "mobile-project-container-content-left mobile-project-container-content-left";
}

.mobile-project-container-headings {
    grid-area: mobile-project-container-headings;
    position: sticky;
    top: 15vh;
    z-index: 3;
    display: flex;
    align-items: flex-start;
    box-sizing: border-box;
    padding-top: 8px;
}

.mobile-project-heading {
    flex: 2;
    font-family: Helvetica, Helvetica, Arial, sans-serif;
    line-height: 23px;
    font-size: 16px;
    font-weight: 700;
}

.mobile-project-date {
    flex: 1;
    line-height: 23px;
    font-size: 16px;
    font-weight: 400;
    font-family: Helvetica, Helvetica, Arial, sans-serif;
    color: #8c8c8c;
}

.mobile-project-container-content-left {
    grid-area: mobile-project-container-content-left;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: minmax(15vh, min-content) minmax(80vh, min-content);
}

.mobile-project-container-content-left-img {
    /* background-color: white; */
    /* display: flex; */
    /* overflow: hidden; */
}

.mobile-project-container-content-left-content {
    box-sizing: border-box;
    padding-top: 2rem;
    text-align: left;
    position: relative;
    font-family: Helvetica, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
}

.mobile-project-container-content-right {
    grid-area: mobile-project-container-content-right;
    text-align: end;
    font-family: Helvetica, Helvetica, Arial, sans-serif;
    /* font-weight: 200; */
    /* line-height: 15px; */
}

.mobile-project-container-content-left-content p {
    /* line-height: 23px; */
}

.is-pinned {
    color: red;
}

.mobile-project-container-wrapper .awssld {
    width: 100%;
    height: auto;
    max-width: 550px;
    --slider-height-percentage: 80%;
    --slider-transition-duration: 200ms;
    --organic-arrow-thickness: 20px;
    --organic-arrow-border-radius: 30px;
    --organic-arrow-height: 40px;
    --organic-arrow-color: #26456f;
    --control-button-width: 40%;
    --control-button-height: 80%;
    --control-button-background: transparent;
    --control-bullet-color: #fafafa;
    --control-bullet-active-color: #d5d5d5;
    --loader-bar-color: transparent;
    --loader-bar-height: 1px;
}


/* Responsiveness */

@media screen and (max-width: 1900px) {
    .mobile-project-container-content-div {
        width: 300px;
    }
}