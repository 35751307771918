.about-container-wrapper{
    display: grid;
    grid-template-columns: minmax(0rem, 1fr) minmax(min-content, 1920px) minmax(0rem, 1fr);
    grid-template-rows: 100vh;
    /* grid-template-rows: minmax(100vh,min-content); */
    padding-left: 2rem;
    padding-right: 2rem;
    grid-template-areas: ". about-container .";
}
.about-container{
    grid-area: about-container;
    display: grid;
    grid-template-columns: 350px minmax(min-content,1570px);
    /* grid-template-columns: 25% 75%; */
    grid-template-rows: 150px minmax(80vh, min-content);
    /* grid-template-rows: 150px 90vh; */
    grid-template-areas: "about-header about-header"
                         "about-left-nav about-content";
}
.about-header{
    grid-area: about-header;
    width: 100%;
    height: 100%;
    border-bottom: 1px solid black;
    position: sticky;
    top: 0;
    box-sizing: border-box;
}
.about-left-nav{
    grid-area: about-left-nav;
    height: 100fr;
    width: 350px;
    border-right: 1px solid black;
    /* display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 95% 5%; */
    position: fixed;
    top: 150px;
    bottom: 0;
    left: 2rem;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 95fr 5fr;
}
.about-left-nav-items {
    overflow-y: auto;
    overflow-x: hidden;
}
.about-content{
    grid-area: about-content;
    background-color: white;
    display: grid;
    grid-template-areas: "aboutContentDiv";
}
.aboutContentDiv{grid-area: aboutContentDiv; 
    background-color: white; 
    width: 550px;
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    /* font-family: Helvetica; */
    font-family: Helvetica, Helvetica, Arial, sans-serif;
    padding-left: 20px;
    padding-top: 15px;
}

.about-left-nav-footer{
    border-top: 1px solid black;
    font-family: Helvetica, Helvetica, Arial, sans-serif;
    font-size: 16px;
position: relative;
}

.contactz{
    position: absolute;
    top: 20%;
}
.IGz{
position: absolute;
top: 20%;

}
.aboutMobileNav{
    display:none;
}
/* Responsiveness */

@media screen and (max-width: 850px) {
    .about-left-nav{
        display: none;
    } 
    .about-content{
        /* display: none; */
    }
    .about-container{
        grid-area: about-container;
        position: relative;
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: 150px 100fr;
        grid-template-areas: "about-header"
        "about-content"
                             /* "aboutMobileNav"; */
    }
    /* Removing padding left, and setting width 100% */ 
    .aboutContentDiv{
        padding-left: 0px;
        width: 100%;
    }

    /* .aboutMobileNav{
        grid-area: aboutMobileNav;
        height: 100%;
        width: 100%;
        position: absolute;
    }
    .aboutMobileNav{
        display:none;
    }    */
    
}