.project-container-wrapper {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: minmax(60vh, min-content);
    border-bottom: 1px solid black;
}

.project-container {
    display: grid;
    padding-left: 3%;
    padding-bottom: 3%;
    grid-template-columns: 70% 30%;
    font-family: Helvetica, Helvetica, Arial, sans-serif;
    grid-template-rows: 10vh minmax(60vh, min-content);
    grid-template-areas: "project-container-headings project-container-headings" "project-container-content-left .";
}

.project-container-headings {
    grid-area: project-container-headings;
    position: sticky;
    top: 112px;
    z-index: 3;
    display: flex;
    align-items: flex-start;
    box-sizing: border-box;
    padding-top: 8px;
}

.project-heading {
    flex: 2;
    font-family: Helvetica, Helvetica, Arial, sans-serif;
    line-height: 23px;
    font-size: 16px;
    font-weight: 700;
}

.project-date {
    flex: 1;
    line-height: 23px;
    font-size: 16px;
    font-weight: 400;
    font-family: Helvetica, Helvetica, Arial, sans-serif;
    color: #8c8c8c;
}

.project-container-content-left {
    grid-area: project-container-content-left;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: minmax(20vh, min-content) minmax(40vh, min-content);
}

.project-container-content-left-img {
    /* background-color: white; */
    /* display: flex; */
    /* overflow: hidden; */
}

.project-container-content-left-content {
    box-sizing: border-box;
    padding-top: 2rem;
    text-align: left;
    position: relative;
    font-family: Helvetica, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
}

.project-container-content-right {
    grid-area: project-container-content-right;
    text-align: end;
    font-family: Helvetica, Helvetica, Arial, sans-serif;
    /* font-weight: 200; */
    /* line-height: 15px; */
}

.project-container-content-left-content p {
    /* line-height: 23px; */
}

.is-pinned {
    color: red;
}

.awssld {
    width: 550px;
    height: 440px;
    --slider-height-percentage: 60%;
    --slider-transition-duration: 200ms;
    --organic-arrow-thickness: 20px;
    --organic-arrow-border-radius: 30px;
    --organic-arrow-height: 40px;
    --organic-arrow-color: #26456f;
    --control-button-width: 40%;
    --control-button-height: 80%;
    --control-button-background: transparent;
    --control-bullet-color: #fafafa;
    --control-bullet-active-color: #d5d5d5;
    --loader-bar-color: transparent;
    --loader-bar-height: 1px;
}


/* Responsiveness */

@media screen and (max-width: 1920px) {
    .project-container-content-div {
        width: 550px;
    }
}