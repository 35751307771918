.mobile-nav-item-container-wrapper{
    /* color: #8c8c8c; */
    display: grid;
    grid-template-columns: 100%;
    /* grid-template-columns: minmax(min-content,300px); */
    grid-template-rows: minmax(40px,min-content);
    border-bottom: 1px solid black;
    position: relative;
    box-sizing: border-box;
    font-family :Helvetica, Helvetica, Arial, sans-serif;
    font-feature-settings: "liga", "lnum";
}
.mobile-nav-item-container-wrapper:hover{
    color: lightgrey;
}
.mobile-item-heading{
    display: flex;
    font-family :Helvetica, Helvetica, Arial, sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    /* color: #8c8c8c; */
    /* color: black; */
}
.mobile-item-date{
    font-family :Helvetica, Helvetica, Arial, sans-serif;
    font-weight: 400;
    color: #8c8c8c;
}
.mobile-item-para{
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;

    /* position: absolute;
    right: 1rem;
    font-family :Helvetica, Helvetica, Arial, sans-serif;
    font-weight: 200;
    top: 16%; */
}

.mobile-nav-item-container-wrapper p{
    /* font-size: 100px; */
    /* font: inherit; */
    /* vertical-align: baseline; */
    /* font-weight: 300; */
 
}

.mobile-nav-item-container-wrapper h2{

}